/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
// React Basic and Bootstrap
import React from 'react';
// import Icon from '@material-ui/core/Icon';
import { FaInstagram, FaWhatsapp, FaFacebook } from "react-icons/fa";
import Image from '../../Layout/image';
// import ContactForm from '../ContactForm';

const ContactDetail = ({ label, value, url, Icon }) => (
  <div className="contact-detail mt-3">
    <div className="icon mt-3 float-left">
      {/* <i className="phone text-muted mdi-36px mr-3"></i> */}
      {Icon && <Icon className="text-muted mdi-36px mr-3" />}
    </div>
    <div className="content mt-3 overflow-hidden d-block">
      {label && <p className="title font-weight-bold mb-0">{label}</p>}
      {value && (
        <a target="_blank" rel="noopener noreferrer" href={url} className="text-primary h6">
          {value}
        </a>
      )}
    </div>
  </div>
);

const ContactUs = ({ title, subtitleNode, content, siteMetadata }) => {
  return (
    <>
      {title || subtitleNode && (
        <div className="row">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              {title && <h2 className="title mb-4">{title}</h2>}
              {subtitleNode}
              {/* <p className="text-muted para-desc mx-auto mb-0">Ada pertanyaan ataupun kendala mengenai produk Kami? </p>
              <p className="text-muted para-desc mx-auto mb-0">Hubungi Kami sekarang juga ya, berlangganan Akun Premium di Maubang pasti Bergaransi Penuh karena kepuasan Pelanggan adalah yang utama!</p> */}
            </div>
          </div>
        </div>
      )}
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1 text-center">
          {/* <ContactForm /> */}
          <p style={{ fontSize: 25, fontWeight: 'bold', marginBottom: 30 }}> Pesan Online Sekarang </p>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div style={{ marginRight: 30 }}>
              <a target="_blank" rel="noopener noreferrer" href={siteMetadata.gofoodLink}>
                <Image className="img" imgID="logoGofood" style={{ width: 100 }} />
              </a>
            </div>
            <div>
              <a target="_blank" rel="noopener noreferrer" href={siteMetadata.grabfoodLink}>
                <Image className="img" imgID="logoGrabfood" style={{ width: 100 }} />
              </a>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 order-1 order-md-2">
          <div className="title-heading ml-lg-4">
            {/* <h3 className="h3 mb-4">Kontak Kami</h3> */}
            <p className="text-muted">
              {content || (
                <>
                  <div> {siteMetadata.address} </div>
                  <div> Jam Operasional: 10:00 - 21:00 Setiap Hari. </div>
                </>
              )}
            </p>

            <ContactDetail
              label="Whatsapp (Pesan Antar)"
              value="+62 852-6448-1801"
              url="https://wa.me/6285264481801?text=Halo+bang%2C+mau+pesan+nih"
              Icon={FaWhatsapp}
            />

            <ContactDetail
              label="Instagram"
              value={siteMetadata.instagram.label}
              url={siteMetadata.instagram.link}
              Icon={FaInstagram}
            />

            <ContactDetail
              label="Facebook"
              value={siteMetadata.facebook.label}
              url={siteMetadata.facebook.link}
              Icon={FaFacebook}
            />

            {/* <ContactDetail
              label="Email"
              value="storetuberki@gmail.com"
              url="mailto:bpklopiga@gmail.com"
              Icon={FaInstagram}
            /> */}

            {/* <div className="contact-detail mt-3">
              <div className="icon mt-3 float-left">
                <i className="material-icons text-muted mdi-36px mr-3">map</i>
              </div>
              <div className="content mt-3 overflow-hidden d-block">
                <h4 className="title font-weight-bold mb-0">Location</h4>
                <Link to="//www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin" className="video-play-icon h6 text-primary">
                  View on Google map
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;