import React from "react"
import { graphql } from "gatsby"
// import { connect } from "react-redux"
// import { createStructuredSelector } from "reselect"
// import styled from "styled-components";

import Layout from "../components/Layout/layout"
import SEO from "../components/Layout/seo"
import FindUs from "../components/HomepageComponents/FindUs";

const Kontak = ({ data }) => {

  return (
    <Layout data={data}>
      <SEO title="Kontak BPK Lopiga Bremaha | Babi Panggang Karo Batam" />
      <FindUs data={data} />
    </Layout>
  )
};

export const query = graphql`
  query KontakPageQuery {
    site {
      siteMetadata {
        title
        description
        title
        address
        phone
        grabfoodLink
        gofoodLink
        facebook {
          label
          link
        }
        instagram {
          label
          link
        }
      }
    }
  }
`;

// Kontak.propTypes = {
//   onGetExamplesData: func.isRequired,
// };

// export const mapStateToProps = createStructuredSelector({
//   examplesData: selectExamplesData(),
// });

// export const mapDispatchToProps = {
//   onGetExamplesData: getExamplesData,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Kontak)
export default Kontak;
