/* eslint-disable react/jsx-props-no-spreading */
import React from "react"

import { Section, Title } from "../../lib/utils"
import ContactUs from "../general/ContactUs";

const FindUs = ({ data }) => {
  return (
    <Section>
      <Title title="Temukan Kami Hari Ini" />
      <div style={{ marginTop: 30 }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d505.5899161834847!2d104.0094690812754!3d1.1358865703538819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31d989f71200cddf%3A0x9fc20530f3ea541c!2sBPK%20Lopiga%20Bremaha%20Batam!5e0!3m2!1sen!2sid!4v1613004038206!5m2!1sen!2sid"
          title="BPK Lopiga Place"
          width="100%"
          height="450"
          frameBorder="0"
          allowFullScreen=""
          ariaHidden="false"
          style={{ border: 0 }}
        />
      </div>
      <div style={{ marginTop: 30 }}>
        <ContactUs siteMetadata={data.site.siteMetadata} />
      </div>
    </Section>
  )
};

export default FindUs;
